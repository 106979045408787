import { PortableText } from "@portabletext/react"
import React from "react"

const ContentContactPage = ({ content }) => {
  return (
    <div className="w-full content p-4 pt-9">
      {content[0] && <PortableText value={content[0]._rawContent} />}
    </div>
  )
}

export default ContentContactPage
