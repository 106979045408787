import React from "react"
import { graphql } from "gatsby"
import StandardPage from "../components/standardPage"
import ContactPage from "../components/contact/ContactPage"
import Seo from "../components/seo"

export default function Subpage({ data }) {
  //   const post = data.markdownRemark
  const { cta, hero, content } = data.sanityPage
  if (data.sanityPage.pageType === "contact") {
    return <ContactPage cta={cta} hero={hero} content={content} />
  }
  return <StandardPage cta={cta} hero={hero} content={content} />
}

export const Head = ({ data }) => {
  return <Seo seo={data.sanityPage.seo} />
}

export const query = graphql`
  query ($pageId: String!) {
    sanityPage(_id: { eq: $pageId }) {
      id
      title
      pageType
      hero {
        contentPosition
        location {
          _key
          _type
          lat
          lng
          alt
        }
        kontaktToPopup
        herotype
        heading
        bgimage {
          asset {
            gatsbyImageData(width: 1000)
          }
        }
        subheading {
          children {
            text
            _type
            _key
            marks
          }
          _rawChildren
        }
      }
      cta {
        primaryButton {
          link
          text
        }
        secondaryButton {
          link
          text
        }
      }
      seo {
        title
        lang
        description
        author
      }
      content {
        ... on SanityImageGrid {
          _key
          _type
          bgColor
          heading
          gridImages {
            _key
            _type
            heading
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
        ... on SanityTextImage {
          _key
          _type
          heading
          layout
          image {
            asset {
              gatsbyImageData
            }
          }
          _rawContent
        }
      }
    }
  }
`
