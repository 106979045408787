import React from "react"
import GoogleMapReact from "google-map-react"
import marker from "../images/marker.png"
import { useSanityClient } from "../hooks/useSanityClient"

const isClient = typeof window !== "undefined"

const Marker = ({ companyadress, companypostalcode }) => (
  <div className="flex google_map ">
    <img className="w-8 pr-2 h-9" src={marker} />
    <div>
      <p className="text-sm font-black m-0 text-red-600 whitespace-nowrap my-auto">
        {companyadress}
      </p>
      <p className="text-sm font-black m-0 text-red-600 whitespace-nowrap my-auto">
        {companypostalcode}
      </p>
    </div>
  </div>
)

export const GoogleMap = ({ hero }) => {
  const { companyadress, companypostalcode } = useSanityClient()
  const { lat, lng } = hero.location

  return (
    <section className="google-map">
      <div className="map elva h-96 ">
        {isClient && (
          <GoogleMapReact
            bootstrapURLKeys={{
              key: process.env.GOOGLEMAPS_API_KEY,
            }}
            defaultCenter={[lat, lng]}
            defaultZoom={15}
          >
            <Marker
              lat={lat}
              lng={lng}
              companyadress={companyadress}
              companypostalcode={companypostalcode}
            />
          </GoogleMapReact>
        )}
      </div>
    </section>
  )
}
