import React from "react"
import { useSanityForm } from "../../hooks/useSanityForm"
import CTAform from "../Form/CTAform"

const ContactForm = () => {
  const sanityForm = useSanityForm()
  return (
    <div className="contact_sidebar md:ml-7 md:mr-5 md:mb-6  w-full p-4 md:pt-9">
      <CTAform sanityForm={sanityForm._rawFormFields} />
    </div>
  )
}

export default ContactForm
